import $ from 'jquery';

import { Fancybox } from "@fancyapps/ui";


import "@fancyapps/ui/dist/fancybox.css";

import './../../scss/blocks/block-section4.scss';

$(document).ready(function() {
    Fancybox.bind("[data-fancybox]", {
        // Your options go here
    });

});